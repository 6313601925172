import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "text-center text-sm text-grey-darker mt-7" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_input_default = _resolveComponent("input-default")!
  const _component_custom_button = _resolveComponent("custom-button")!
  const _component_auth_container = _resolveComponent("auth-container")!

  return (_openBlock(), _createBlock(_component_auth_container, null, {
    default: _withCtx(() => [
      _cache[4] || (_cache[4] = _createElementVNode("div", { class: "w-full flex flex-col justify-start items-start gap-y-4 overflow-auto scrollbar pr-1" }, null, -1)),
      _createElementVNode("h6", null, _toDisplayString(_ctx.$t("auth.forgot_password")), 1),
      _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.$t("auth.forgot_password_text")), 1),
      _createVNode(_component_input_default, {
        hasMeta: true,
        class: "w-full mt-5",
        id: 'email',
        name: 'email',
        modelValue: _ctx.resetPasswordMail,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.resetPasswordMail) = $event)),
        type: 'text',
        onEnter: _cache[1] || (_cache[1] = ($event: any) => (_ctx.userForgotPassword())),
        label: _ctx.$t('auth.email')
      }, null, 8, ["modelValue", "label"]),
      _createVNode(_component_custom_button, {
        id: 'forgot-password-button',
        class: "w-full mt-5",
        buttonStyle: _ctx.buttonStyle,
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.userForgotPassword()))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t("auth.reset_password")), 1)
        ]),
        _: 1
      }, 8, ["buttonStyle"]),
      _createVNode(_component_custom_button, {
        id: 'cancel-button',
        class: "w-full mt-5",
        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$router.push({ name: _ctx.cancelRoute }))),
        isPlainButton: true
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.send ? _ctx.$t("auth.resend_email") : _ctx.$t("auth.cancel")), 1)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}