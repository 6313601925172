
import CustomButton from "@/components/inputs/customButton/CustomButton.vue";
import AuthContainer from "./containers/AuthContainer.vue";
import InputDefault from "@/components/inputs/inputDefault/InputDefault.vue";
import { computed, defineComponent, onMounted, ref } from "vue";
import { useAuthStore } from "@/store/auth";
import { useHelpersStore } from "@/store/helpers";
import { useRoute, useRouter } from "vue-router";
import { getCookie } from "@/common/cookies";
import { storeToRefs } from "pinia";
import { useI18n } from "vue-i18n";
import { ToastMessageType } from "@/components/helpers/theToastMessage/toast_message_helpers";
export default defineComponent({
    name: "ForgotPassword",
    components: { AuthContainer, CustomButton, InputDefault },
    setup() {
        const { t } = useI18n();
        const { forgotPassword } = useAuthStore();
        const { resetPasswordMail } = storeToRefs(useAuthStore());
        const { setLoadingId, removeLoadingId, setToastMessage } =
            useHelpersStore();
        const send = ref<boolean>(false);

        const router = useRouter();

        async function userForgotPassword(): Promise<void> {
            setLoadingId("forgot-password-button");
            const response = await forgotPassword({
                email: resetPasswordMail.value,
            });
            if (!response) {
                setToastMessage(
                    ToastMessageType.ERROR,
                    t("forgot_password_failed")
                );
            }
            removeLoadingId("forgot-password-button");
        }

        const route = useRoute();
        const buttonStyle = computed(() => {
            let style = {};
            if (route.name?.toString().includes("CustomerAuth")) {
                style = {
                    standardBackgroundColor: "black-lightest",
                    standardBackgroundHoverColor: "black-light",
                    standardBackgroundPressedColor: "black-light",
                    standardBorderPressedColor: "black-light",
                    standardBorderFocusColor: "black-light",
                };
            }
            return style;
        });

        const cancelRoute = computed(() => {
            let routeName = "AuthLogin";
            if (route.name?.toString().includes("CustomerAuth")) {
                routeName = "CustomerAuthLogin";
            }
            return routeName;
        });

        onMounted(() => {
            if (getCookie("logged_in")) {
                router.push({ name: "NormAdministration" });
            }
        });

        return {
            send,
            resetPasswordMail,
            userForgotPassword,
            buttonStyle,
            cancelRoute,
        };
    },
});
